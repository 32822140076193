


.blue-sign-popup {
    color: #ffffff;
    font-family: Roboto;
    font-weight: bold;
    width: 240px;
    font: Roboto;
}

.blue-sign-popup .leaflet-popup-content-wrapper, .blue-sign-popup .leaflet-popup-tip {
    background: #0087ff;
    color: #ffffff;
}

.blue-sign-popup .header {
    font-size: 18px
}

.blue-sign-popup .text {
    font-weight: normal;
    font-size: 16px
}

.blue-sign-popup img {
    width: 195px;
}
.language-selector {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1001;
}

.language-flag {
    margin: 7px;
    width: 25px;
}

.selected-language {
  border-radius: 50%;
  box-shadow: 0px 0px 9px 7px #FFFFFF;
}
.leaflet-container {
    height: 100vh;
    width: 100%;
    padding-left: 0px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

