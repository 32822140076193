.blue-sign-popup {
    color: #ffffff;
    font-family: Roboto;
    font-weight: bold;
    width: 240px;
    font: Roboto;
}

.blue-sign-popup .leaflet-popup-content-wrapper, .blue-sign-popup .leaflet-popup-tip {
    background: #0087ff;
    color: #ffffff;
}

.blue-sign-popup .header {
    font-size: 18px
}

.blue-sign-popup .text {
    font-weight: normal;
    font-size: 16px
}

.blue-sign-popup img {
    width: 195px;
}